//全体で適用、使用できるjavascript
import "slick-carousel";
import $ from "jquery";

//読み込み後実行
$(() => {
  //spヘッダー　ハンバーガー
  $(".header__navSpMenuBtn").on("click", function () {
    $("body").toggleClass("subNavOpen");
  });

  //spヘッダー　アコーディオン
  $(".subNavListItem--ac >span").on("click", function () {
    $(this).parents(".subNavListItem").find(".subNavAcList").slideToggle();
    $(this).toggleClass("open");
  });

  //spフッター　アコーディオン
  $(".footer__linkItem--ac >a").on("click", function (e) {
    if (isSp()) {
      $(this).blur();
      e.preventDefault();
      e.stopPropagation();

      $(this).parents(".footer__linkItem").find(".footer__linkSubList").slideToggle();
      $(this).toggleClass("open");
    }
  });

  //sp各サービスメニューのリンク
  //家事代行：ページ内リンクの場合、メニューの閉じ動作などが必要になるため、location対応
  $(".serviceSpMenu a").on("click touch", function (e) {
    const href = $(this).attr("href");

    if (href.indexOf("#") !== -1) {
      window.location = href;
      location.reload(true);
    }
  });

  //要素が画面内に入ればフェイドイン
  if ($(".fadein").length != -1) {
    $(window).on("scroll.fadein", function () {
      $(".fadein").each(function () {
        const position = $(this).offset().top;
        const scroll = $(window).scrollTop();
        const windowHeight = $(window).height();
        if (scroll > position - windowHeight + 100) {
          $(this).addClass("fadein--active");
        }
      });

      if ($(".fadein").length === 0) {
        $(window).off("scroll.fadein");
      }
    });
  }

  $("a")
    .on("mouseover", function () {
      $(this).addClass("linkHover");
    })
    .on("mouseleave", function () {
      $(this).removeClass("linkHover");
    });

  //スムーススクロール
  $('a[href^="#"]').on("click", function (e) {
    let speed = 500;
    let href = $(this).attr("href");
    let target = href == "#" || href == "" ? "html" : href;

    pageScroll(target, speed);
    return false;
  });

  //SNS共有遷移
  $("[data-buttonType='snsShare']").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    const href = $(this).attr("href");

    if (href) snsSare(href);
    return false;
  });

  //もっと見る
  if ($("[data-button='addElem']").length > 0) {
    $("[data-button='addElem']").on("click", function (e) {
      addElem(e.currentTarget);
    });

    //非表示要素がない場合はボタンを消す
    if ($("[data-button='addElem']").parents("section").find(".fadeinFix:not(.fadeinFix--active)").length === 0) $("[data-button='addElem']").hide();
  }

  // フッター：バナーエリア
  $(".slickBanner").each(function () {
    var i,
        e,
        t;
    i = $(this),
    e = !1,
    t = !1,
    i.find(".footer__bannerItem").length > 3 && (e =! 0),
    i.find(".footer__bannerItem").length > 1 && (t =! 0),
    i.slick({
        autoplay: !0,
        infinite: !0,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: e,
        arrows: !1,
        speed: 1e3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: t,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
  })
});

//ページ内リンク付きの場合の動作
if (location.hash) {
  $("html").addClass("nofadein");

  window.addEventListener("load", () => {
    $("body,html").stop().scrollTop(0);
    pageScroll(location.hash);
  });
}

function isPc() {
  return $(window)[0].innerWidth >= 768;
}

function isSp() {
  return $(window)[0].innerWidth < 768;
}

function pageScroll(target, speed = 500) {
  //ページ内リンクの場合、初期非表示だとスクロール不良を起こすため、無効に
  $("html").addClass("fadein--not");

  let targetElem = $(target).length > 0 ? $(target) : $("html");
  let position = targetElem.offset().top;
  if (isSp()) {
    position = position - $("header").outerHeight();
    if ($(".serviceSpMenu").length > 0 && $(".serviceSpMenu").is(":visible")) {
      position = position - $(".serviceSpMenu__head").outerHeight();
    }
  }
  $("html, body").animate({ scrollTop: position }, speed, "swing");
}

function snsSare(href) {
  const urlEncodeText = encodeURIComponent(window.location.href);
  const url = `${href}${urlEncodeText}`;

  window.open(url, "_blank");
}

function addElem(target) {
  const parentElem = $(target).parents("section");
  const List = parentElem.find(".fadeinFix:not(.fadeinFix--active)");

  if (List.length <= 5) $(target).hide();
  //非表示要素を5つずつ表示
  List.each((index, elem) => {
    $(elem).removeClass("fadeinFix--nonActive");
    setTimeout(() => {
      $(elem).addClass("fadeinFix--active");
    }, 1);

    if (index === 4) return false;
  });
}
